import React from 'react';
import Button from '../components/Button/Button';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo';
import Astra from '../components/Images/Logo';
import SRE from '../components/Images/Sre';
import EnamLima from '../components/Images/EnamLima'
import SatuIndonesia from '../components/Images/SatuIndonesia';
import { Link } from 'gatsby';


// const ketentuan = [
//     "Inovasi yang didaftarkan harus sesuai dengan tema dan fokus InnovNation.",
//     "Setiap kelompok hanya diperbolehkan mengirimkan 1 (satu) Judul Inovasi yang dilengkapi oleh dokumen pendukung.",
//     "Setiap Inovasi merupakan buatan sendiri dan bukan merupakan duplikat (plagiat) dari orang lain.",
//     "Setiap Inovasi yang didaftarkan belum pernah dikomersialisasikan dan diikutsertakan dalam lomba sejenis di tingkat nasional maupun internasional.",
//     "Judul yang digunakan selama project Inovasi berlangsung tidak boleh berganti.",
//     "Seluruh materi yang disampaikan oleh peserta untuk kepentingan penjurian akan dijaga kerahasiaannya dan menjadi hak milik PT Astra International Tbk.",
//     "Peserta yang telah mendaftar wajib membaca dan mematuhi seluruh persyaratan dan peraturan dalam InnovNation ini.",
//     "Jika ada persyaratan / ketentuan yang tidak dipenuhi oleh peserta, maka panitia berhak menolak dan bahkan melakukan diskualifikasi jika dirasa perlu.",
//     "Keputusan juri & panitia bersifat mutlak dan tidak dapat diganggu gugat."
// ]
// const ketentuanItems = ketentuan.map((val, idx) =>
//   <li key={idx}>
//     {val}
//   </li>
// );

const InnovNation = () => (
  <Layout withRegister>
    <SEO title="InnovNation" />
    <section className="">
      <div
        className="py-10 md:py-20 text-white flex"
        style={{
          backgroundImage: `url("https://i.ibb.co/zPvHKnQ/Landing.png")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          minWidth: '100%'
        }}
      >
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left w-full">
          <div className="flex justify-between items-center">
              <div className="w-32 lg:w-40 mb-8 lg:mb-10">
                <Astra />
              </div>
              <div className="w-16 lg:w-24 mb-8 lg:mb-10">
                <EnamLima />
              </div>
              <div className="w-24 lg:w-32 mb-8 lg:mb-10">
                <SatuIndonesia />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="about" className="">
      <div className="container mx-auto text-center flex items-center flex-col">
        <div className="text-center md:text-left m-8 md:m-12">
          <h1 className="text-2xl md:text-4xl font-bold text-center text-primary">Digital Innovation & Technology Competition</h1>
          <p className="text-base md:text-xl mb-1 mt-4 text-center">
          Digital Innovation & Technology Competition merupakan wadah bagi pelajar dan mahasiswa untuk berkompetisi dalam berbagi ide dan inovasi untuk menjawab tantangan bisnis & sustainability.<br/><br/>
          Saat ini, digitalisasi sangat mempengaruhi kita semua dan berkembang dengan pesat di dunia, ditambah dengan adanya pandemi Covid-19 yang mengubah cara kita hidup dan bekerja.<br/><br/>
          Kini saatnya Anda mengikuti kompetisi ini, karena Digital Innovation & Technology Competition adalah tempat yang tepat bagi Anda untuk berbagi ide dan inovasi khususnya pada bidang mobility dan sustainability dengan menghadirkan solusi yang bermanfaat, praktis, dan mampu menjawab tantangan bisnis dan sustainability.<br/><br/>
          Menuju HUT ke-65 Astra, saatnya kita lebih semangat bergerak dan tumbuh bersama!<br/><br/>
          <p className='text-primary text-xl md:text-2xl'>#KitaSATUIndonesia</p>
          </p>
        </div>
      </div>
    </section>
    <section id="theme" className="">
      <div className="container mx-auto text-left flex items-center flex-col">
        <div className="m-8 md:m-12">
          <h1 className="text-2xl md:text-4xl font-bold text-center text-primary">Tema</h1>
          <p className="text-xl md:text-2xl mt-4 text-center">
            “Transforming Innovation for Sustainability”
          </p>
        </div>
      </div>
      </section>
      <section id="theme" className="">
      <div className="container mx-auto text-left flex items-start flex-col">
        <div className="mx-8 md:mx-12">
        <p className="text-base md:text-xl mb-1">
        Stream of Innovation:
        </p>
          <ul className="list-outside list-disc md:text-xl mt-4 ml-6 text-justify">
          <li><b>Future of mobility:</b><br/>
              Inovasi dan Ide dalam Future of Mobility yang dilakukan dapat meliputi pengembangan teknologi baru dalam transportasi atau mobility, ekosistem dalam  future of mobility atau transportasi, ataupun inovasi lainnya dalam ruang lingkup future of mobility atau transportasi.<br/>
              Contoh scope inovasi dalam Future of Mobility diantaranya :
            <ul className="list-outside list-disc md:text-xl my-2 ml-12">
              <li>
              Electrification of Vehicles
              </li>
              <li>
              Connected & Autonomous Vehicles
              </li>
              <li>
              Mobility-as-a-Service
              </li>
            </ul>
            </li>
            <li>
            <b>Future of energy:</b><br/>
            Inovasi dan Ide dalam Future of Mobility yang dilakukan dapat meliputi pengembangan teknologi baru dan inovasi dalam efisiensi energi dan energi baru terbarukan. Inovasi yang dihasilkan harapannya dapat menurunkan Greenhouse Gas (GHG) sehingga dapat membantu tercapainya sustainability.<br/>
            Contoh scope inovasi dalam Future of Energy diantaranya :
            <ul className="list-outside list-disc md:text-xl my-2 ml-12">
              <li>
              Pengembangan sustainable production process dalam industri
              </li>
              <li>
              Pengembangan sumber energi baru terbarukan (panel surya, mikro hidro, dll)
              </li>
              <li>
              Community's daily sustainability movement 
              </li>
            </ul>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section id="theme" className="">
      <div className="container mx-auto text-left flex items-start flex-col mt-5">
        <div className="m-8 md:m-12">
          <h1 className="text-2xl md:text-4xl font-bold text-blue text-center md:text-left text-primary">Ketentuan Umum</h1>
          <ul class="list-outside list-disc md:text-xl mt-4 ml-6 text-justify">
          <li>
          Inovasi yang didaftarkan harus sesuai dengan tema yang diangkat pada <i>Digital Innovation & Technology Competition</i>
          </li>
          <li>
          Setiap kelompok hanya diperbolehkan mengirimkan satu judul inovasi yang dilengkapi oleh dokumen pendukung.
          </li>
          <li>
          Setiap inovasi bersifat orisinil dan bukan merupakan duplikat (plagiasi) dari orang lain.
          </li>
          <li>
          Setiap inovasi yang didaftarkan belum pernah dikomersialisasikan dan diikutsertakan dalam lomba sejenis di tingkat nasional maupun internasional. 
          </li>
          <li>
          Judul yang digunakan selama proyek inovasi berlangsung tidak boleh diganti.
          </li>
          <li>
          Seluruh materi yang disampaikan oleh peserta untuk kepentingan penjurian akan dijaga kerahasiaannya dan menjadi hak milik PT Astra International Tbk.
          </li>
          <li>
          Peserta yang telah mendaftar wajib membaca dan mematuhi seluruh persyaratan dan peraturan dalam <i>Digital Innovation & Technology Competition</i>
          </li>
          <li>
          Jika ada persyaratan/ketentuan yang tidak dipenuhi oleh peserta, maka panitia berhak menolak dan bahkan melakukan diskualifikasi jika dirasa perlu.
          </li>
          <li>
          Keputusan juri dan  panitia bersifat mutlak dan tidak dapat diganggu gugat.
          </li>
          </ul>
        </div>
      </div>
    </section>
    <section id="theme" className="">
      <div className="container mx-auto text-left flex items-start flex-col">
        <div className="m-8 md:m-12">
          <h1 className="text-2xl md:text-4xl font-bold text-blue text-center md:text-left text-primary">Persyaratan Peserta</h1>
          <ul class="list-outside list-disc md:text-xl mt-4 ml-6 text-justify">
              <li>Peserta <i>Digital Innovation & Technology Competition</i> harus terdaftar sebagai siswa aktif untuk kategori SMA/SMK dan mahasiswa D3 atau S1 aktif untuk kategori Perguruan Tinggi selama proyek berlangsung.</li>
              <li>Peserta merupakan kelompok siswa/mahasiswa aktif yang terdiri dari minimal 2 orang dan maksimal 3 orang.</li>
              <li>Setiap kelompok boleh terdiri dari jurusan atau program studi dan universitas yang berbeda.</li>
              <li>Peserta yang sedang mendapatkan sanksi akademik tidak diperkenankan mengikuti kompetisi.</li>
              <li>Setiap tim harus didampingi oleh satu atau lebih guru/dosen pembimbing dalam pembuatan inovasi.</li>
              <li>Setiap peserta harus mengisi formulir pendaftaran secara online di <a href="http://bit.ly/DIGITECH2022" target="_blank" className="hover:underline cursor-pointer text-primary"><b>bit.ly/DIGITECH2022</b></a></li>
              <li>Peserta wajib mengunggah twibbon yang telah disediakan pada akun instagram pribadi dan mention ke akun instagram (<b>@pranaraksa.astra</b> dan <b>@satu_indonesia</b>) serta tag 5 orang teman.</li>
              <li>Peserta wajib mengunggah poster <i>Digital Innovation & Technology Competition</i> di instagram story.</li>
          </ul>
        </div>
      </div>
    </section>
    <section id="theme" className="pb-20">
      <div className="container mx-auto text-left flex items-start flex-col">
        <div className="m-8 md:m-12">
          <h1 className="text-2xl md:text-4xl font-bold text-blue text-center md:text-left text-primary">Mekanisme Pendaftaran</h1>
          <ul class="list-outside list-disc md:text-xl mt-4 ml-6 text-justify">
              <li>Peserta dapat mendaftarkan diri dengan cara mengisi formulir pendaftaran dan mengunggah persyaratan yang dibutuhkan pada situs website <a href="http://bit.ly/DIGITECH2022" target="_blank" className="hover:underline cursor-pointer text-primary"><b>bit.ly/DIGITECH2022</b></a></li>
              <li>Periode pendaftaran adalah <b>10 Desember 2021 - 9 Januari 2022.</b></li>
              <li>Dokumen yang diperlukan saat pendaftaran:</li>
              <ul class="list-outside list-disc md:text-xl my-2 ml-12">
                  <li>Formulir pendaftaran yang ada pada situs website <a href="http://bit.ly/DIGITECH2022" target="_blank" className="hover:underline cursor-pointer text-primary"><b>bit.ly/DIGITECH2022</b></a></li>
                  <li>Dokumen abstrak yang sudah diisi lengkap, boleh melampirkan dokumen pendukung dalam bentuk infografis atau data pendukung lainnya</li>
              </ul>
              <li>Seluruh dokumen yang diperlukan saat pendaftaran diunggah dalam bentuk PDF dengan maksimal ukuran 5 MB pada <a href="http://bit.ly/DIGITECH2022" target="_blank" className="hover:underline cursor-pointer text-primary"><b>bit.ly/DIGITECH2022</b></a></li>
              <li>Seluruh dokumen harus dikumpulkan <b>MAKSIMAL 9 Januari 2022 pukul 23.59 WIB.</b> Setiap dokumen yang dikumpulkan melewati batas pengumpulan akan dikenakan pengurangan nilai.</li>
              <li>Informasi terkait dengan <i>Digital Innovation & Technology Competition: Future of Energy </i> dapat dilihat pada situs website <a href="http://bit.ly/DIGITECH2022" target="_blank" className="hover:underline cursor-pointer text-primary"><b>bit.ly/DIGITECH2022</b></a></li>
          </ul>
        </div>
      </div>
    </section>
  </Layout>
);

export default InnovNation;
